import React from "react"
import Layout from "../components/Layout"
import { ValueProp, ValuePropHeader } from "../components/ValueProp/ValueProp"
import Awards from "../components/Awards/Awards"
import { graphql, useStaticQuery } from "gatsby"
import ImageAndText from "../components/ImageAndText/ImageAndText"
import PreHeading from "../components/PreHeading"
import { ButtonA } from "../components/Button/Button"
import { SoloToOchestraEn } from "../components/SoloToOrchestra/SoloToOrchestra.de"
import CustomerSlider from "../components/CustomerSlider"
import { Quote } from "../components/Quote/Quote"
import { StartNowEn } from "../components/StartNow"
import { PageType } from "../index"
import Seo from "../components/Seo"

const AboutUsEn: PageType = ({ location }) => {
    const {
        jetShow,
        jetShowTablet,

        teubert,
    } = useStaticQuery(graphql`
        query AboutUsEn {
            jetShow: file(relativePath: { eq: "pages/about-us/jet-show.jpg" }) {
                ...ImageAndTextSideImage
            }
            jetShowTablet: file(relativePath: { eq: "pages/about-us/jet-show.jpg" }) {
                ...ImageAndTextSideImage_Tablet
            }
            teubert: file(relativePath: { eq: "pages/about-us/teubert.jpg" }) {
                childImageSharp {
                    fixed(width: 80, height: 80) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
        }
    `)
    return <Layout location={location}>
        <Seo
            title="Pioneers of corporate management"
        />
        <section className="fancy-content-lg-dark">
            <Quote
                cite="Prof. Dr. Michael Teubert"
                logo={teubert}
                logoFirst
                logoClass="rounded-full overflow-hidden"
                quoteClassName="h2"
            >
                Great Leadership is not complicated, but complex.
            </Quote>
        </section>
        <ValueProp>
            <ValuePropHeader
                preHeading="Our venture"
                heading="Empowering People and Human Capital"
            >
                <p>
                    Our products are tailor-made solutions ensuring your organizational growth and success. Whilst success is undoubtedly the outcome of each and every employee’s valuable contribution, it is the human capital that we strive to strengthen and empower in a unique manner. We have travelled a long way to explore the exceptional essence of this formula: tangible change can only be possible when technology is clearly aimed at none other than people. Thus, our products are meticulously crafted keeping people as its core element for they are its end user.
                </p>

                <div className="mt-8">
                    <Awards />
                </div>
            </ValuePropHeader>
        </ValueProp>
        <section>
            <ImageAndText fancy image={jetShow} tabletImage={jetShowTablet}>
                <PreHeading>The “First Who, Then What” Concept</PreHeading>
                <h2>Leading. Together.</h2>
                <p>
                    From the very beginning, Quadio epitomizes expertise on leadership, coaching and software at the very highest standard. All this would not have been feasible without the  support of our highly committed team consisting of experts on strategic leadership, product engineering, design and networking. In a nutshell, Quadio is beyond a mere product for strengthening your organization: The secret lies in our uniqueness towards leadership and management.
                </p>
                <ButtonA
                    appearance="secondary"
                    href={`mailto:info@quadio.de?subject=${encodeURIComponent("Become our Partner")}`}
                >
                    Become our Partner
                </ButtonA>
            </ImageAndText>
        </section>
        <SoloToOchestraEn />
        <section className="section-x-margin">
            <div className="hidden" aria-hidden={false}>
                Quadio Customers
            </div>

            <CustomerSlider />
        </section>
        <StartNowEn
            title="Get Started with Quadio"
        />
    </Layout>
}

export default AboutUsEn
